import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';
import { notificationService } from './notifications';
import { masterDataService } from './masterData';

const apiHost = process.env.REACT_APP_API_HOST;

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  tagTypes: ['Project'],
  refetchOnMountOrArgChange: true,
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        return headers;
      }
      return {};
    },
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: (filters) => {
        const {
          page, size, sortColumn, sortOrder, ...otherFilters
        } = filters;
        return {
          url: `/projects/filter?page=${page}&size=${size}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
          method: 'post',
          data: otherFilters,
        };
      },
      providesTags: ['Project'],
    }),
    createProject: builder.mutation({
      query: (projectData) => ({
        url: '/projects/',
        method: 'post',
        data: projectData,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            notificationService.util.invalidateTags(['Notifications']),
          );
          dispatch(
            masterDataService.util.invalidateTags(['MasterData']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    getProjectById: builder.query({
      query: (projectId) => ({
        url: `/projects/${projectId}`,
        method: 'get',
      }),
      providesTags: ['Project'],
    }),
    updateProject: builder.mutation({
      query: ({ projectId, projectData }) => ({
        url: `/projects/${projectId}`,
        method: 'put',
        data: projectData,
      }),
      invalidatesTags: ['Project', 'ProjectHistory'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            projectsApi.util.invalidateTags(['Project']),
          );
          dispatch(
            notificationService.util.invalidateTags(['Notifications']),
          );
          dispatch(
            masterDataService.util.invalidateTags(['MasterData']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    getProjectHistory: builder.query({
      query: (projectId) => ({
        url: `/projects/${projectId}/project-history`,
        method: 'get',
      }),
      providesTags: ['ProjectHistory'],
    }),
    UpdateCompletedProjectStatus: builder.mutation({
      query: ({ projectId }) => ({
        url: `/projects/${projectId}/status`,
        method: 'put',
      }),
      invalidatesTags: ['Project', 'ProjectHistory'],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useUpdateProjectMutation,
  useGetProjectHistoryQuery,
  useUpdateCompletedProjectStatusMutation,
} = projectsApi;
