import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';
import { projectsApi } from './project';
import { taskService } from './task';

const apiHost = process.env.REACT_APP_API_HOST;

export const milestoneService = createApi({
  reducerPath: 'milestoneService',
  tagTypes: ['Milestones'],
  refetchOnMountOrArgChange: true,
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };
        return headers;
      }
      return {};
    },
  }),
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getProjectMilestones: builder.query({
      query: (projectId) => ({
        url: `/projects/${projectId}/milestones`,
        method: 'get',
      }),
      providesTags: ['Milestones'],
    }),
    getAllMilestones: builder.query({
      query: () => ({
        url: '/projects/milestones',
        method: 'get',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(projectsApi.util.invalidateTags(['Project']));
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    editProjectMilestone: builder.mutation({
      query: ({ projectId, milestoneList }) => ({
        url: `/projects/${projectId}/milestones`,
        method: 'put',
        data: milestoneList,
      }),
      invalidatesTags: ['Milestones'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(projectsApi.util.invalidateTags(['Project', 'ProjectHistory']));
          dispatch(
            taskService.util.invalidateTags(['Tasks']),
          );
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
    addProjectMilestone: builder.mutation({
      query: ({ projectId, milestoneList }) => ({
        url: `/projects/${projectId}/milestones`,
        method: 'post',
        data: milestoneList,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(projectsApi.util.invalidateTags(['Project']));
        } catch (err) {
          // eslint-disable-next-line
          console.log(err.error.message);
        }
      },
    }),
  }),
});

export const {
  useGetProjectMilestonesQuery,
  useGetAllMilestonesQuery,
  useEditProjectMilestoneMutation,
  useAddProjectMilestoneMutation,
} = milestoneService;
