import { useState } from 'react';
import { useAddTemplateMutation, useUpdateTemplateConfigurationMutation } from '../../../../Redux/services/report';
import TemplatesList from './TemplatesList';

function Templates({
  selectedTemplateId, setSelectedTemplateId, customCheckedFields, isMobileCompatible,
  handleMobileCompatibility, setIsMobileCompatible,
}) {
  const [updateTemplateConfig, {
    isLoading: updateTemplateLoading,
  }] = useUpdateTemplateConfigurationMutation();
  const [createTemplate, { isLoading: createTemplateLoading }] = useAddTemplateMutation();
  const [templateName, setTemplateName] = useState('');
  const [selectedTemplateIdForUpdation, setSelectedTemplateIdForUpdation] = useState(null);

  const updateTemplateHandler = async () => {
    const templateConfigurations = customCheckedFields.map(({ label, ...field }) => field);
    try {
      const response = await updateTemplateConfig({
        id: selectedTemplateIdForUpdation,
        data: { configuration: templateConfigurations },
      });
      if (response?.data?.success) {
        window.toastr.success('Template updated successfully!');
      }
      if (response.error) {
        window.toastr.error(response.error.message);
      }
    } catch (error) {
      window.toastr.error('Something went wrong!');
    }
  };

  const createTemplateHandler = async () => {
    const reqBody = customCheckedFields.filter((field) => field?.report || field?.filter)
      .map((field) => ({
        key: field.key,
        report: field.report,
        filter: field.filter,
      }));
    try {
      const response = await createTemplate({
        data: {
          name: templateName, configurations: reqBody, mobile: isMobileCompatible,
        },
      });
      if (response?.data?.success) {
        window.toastr.success('Template created successfully!');
        setTemplateName('');
      }
      if (response.error) {
        window.toastr.error(response.error.message);
      }

    } catch (error) {
      window.toastr.error('Something went wrong!');
    }
  };

  return (
    <>
      <div className="px-7 py-5 d-flex justify-content-between">
        <div className="fs-5 text-dark fw-bold">Timesheet Templates</div>
        <div className="card-toolbar">
          <i
            className="fa fa-close close-template-dropdown"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              document.querySelector('.btn.template-btn').classList.remove('show'); document.querySelector('.template-dropdown').classList.remove('show');
            }}
            aria-hidden="true"
            aria-label="Close"
          />
        </div>
      </div>
      <div className="separator border-gray-200" />
      <div className="border-dashed border-gray-300 rounded px-5 m-5 pb-7 pt-8" style={{ border: '1px dashed #333' }}>
        <div className="d-flex flex-column">
          <div className="d-flex align-self-center">
            <div className="flex-grow-1 me-3 report-temp-save">

              <button
                type="button"
                onClick={updateTemplateHandler}
                className="btn btn-icon btn-sm btn-primary w-60px me-5"
              >
                {updateTemplateLoading
                  ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                    >
                      <span className="sr-only">
                        Loading...
                      </span>
                    </div>
                  )
                  : <>Save </>}
              </button>
            </div>
            <div className="flex-grow-1 w-150px">
              <input
                required
                value={templateName}
                onChange={(e) => {
                  if (!(/[^a-zA-Z0-9\-\s_']/.test(e.target.value))) {
                    setTemplateName(e.target.value);
                  }
                }}
                type="text"
                className="form-control"
                placeholder="Enter name"
              />
            </div>
            <button
              type="button"
              className="btn btn-icon btn-sm btn-primary w-100px"
              onClick={createTemplateHandler}
              style={{
                borderRadius: 5,
                marginLeft: '-10px',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              {createTemplateLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )
                : <i className="la la-save fs-3" />} Save as
            </button>
          </div>
        </div>
      </div>
      <TemplatesList
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        setSelectedTemplateIdForUpdation={setSelectedTemplateIdForUpdation}
        handleMobileCompatibility={handleMobileCompatibility}
        setIsMobileCompatible={setIsMobileCompatible}
      />
    </>
  );
}

export default Templates;
