/* eslint-disable import/no-extraneous-dependencies */
import {
  Modal, Form, Row, Col,
} from 'react-bootstrap';
import FlatPickr from 'react-flatpickr';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { useGetProjectByIdQuery } from '../../Redux/services/project';
import { useGetMasterDataQuery } from '../../Redux/services/masterData';
import { useAddTaskMutation, useUploadAttachmentsMutation } from '../../Redux/services/task';
import { userStatusConstants } from '../../utils/constant';

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    fontSize: '1.1rem',
    color: 'var(--bs-gray-700)',
  }),
  cursor: 'default',
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
};

function AddTask({
  show, onHide, projectName, projectId, usersData,
}) {
  const { data: masterData } = useGetMasterDataQuery();
  const [addTask, { isLoading }] = useAddTaskMutation();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [uploadAttachments] = useUploadAttachmentsMutation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      project: '',
      users: [],
      startDate: '',
      endDate: '',
      description: '',
    },
  });
  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (files.length >= 5) {
      return window.toastr.error('Number of Files exceeds 5');
    }

    if (acceptedFiles?.length) {
      try {
        const attachmentObj = {
          ...acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file),
          })),
        };
        const response = await uploadAttachments({ data: attachmentObj });
        if (response?.data?.success) {
          setFiles((previousFiles) => [
            ...previousFiles,
            ...acceptedFiles.map((file) => Object.assign(file, {
              preview: URL.createObjectURL(file),
              id: response?.data?.data[0],
            })),
          ]);
          setAttachments((prevIds) => ([...prevIds, response?.data?.data[0]]));
        } else {
          window.toastr.error(response?.error?.data?.message ?? 'File could not be uploaded, please try again');
        }
        URL.revokeObjectURL(attachmentObj.preview);
      } catch (error) {
        window.toastr.error(error?.message);
      }

    }
    if (rejectedFiles?.length) {
      if (rejectedFiles[0]?.errors?.[0]?.code === 'file-invalid-type') {
        window.toastr.error('File type is not acceptable');
      } else if (rejectedFiles[0]?.errors?.[0]?.code === 'too-many-files') {
        window.toastr.error('Upload only one file at a time');
      } else if (rejectedFiles[0]?.errors?.[0]?.code === 'file-too-large') {
        window.toastr.error('Exceeds max file size');
      } else {
        window.toastr.error('Exceeds max file size / file type is not acceptable');
      }
    }
    return null;
    // eslint-disable-next-line
  }, [files.length]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg'],
      'text/plain': ['.txt'],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/msword': ['.doc'],
      'application/pdf': [],
      'text/csv': ['.csv'],
    },
    noDragEventsBubbling: true,
    maxSize: 1024 * 1024 * 5,
    onDrop,
    maxFiles: 1,
  });

  const projectWatched = watch('project');
  useEffect(() => {
    resetField('users');
  }, [projectWatched, resetField]);

  useEffect(
    () => () => files?.forEach((file) => URL.revokeObjectURL(file.preview)),
    [files],
  );

  const { data: projectData } = useGetProjectByIdQuery(projectWatched?.value, {
    skip: !projectWatched?.value,
  });

  useEffect(() => {
    if (projectId && masterData && masterData.data && masterData.data.projects) {
      const project = masterData.data.projects.find((projects) => projects.id === projectId);
      if (project) {
        setValue('project', { label: project.name, value: project.id });
      }
    }
  }, [masterData, setValue, projectId]);

  const onSubmit = async (data, { reOpen = false }) => {
    const buttons = document.getElementsByClassName('save-btn');
    buttons?.forEach((button) => {
      const btn = button;
      btn.disabled = true;
    });
    const {
      users, project, startDate, endDate,
    } = data;
    const formattedStartDate = moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const usersId = users.map((user) => user.value);
    const reqBody = {
      ...data,
      users: usersId,
      project: project.value,
      attachmentIds: attachments,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    const response = await addTask(reqBody);
    buttons?.forEach((button) => {
      const btn = button;
      btn.disabled = false;
    });
    if (response?.data?.success) {
      window.toastr.success('Task saved successfully!');
      reset({
        title: '',
        project: '',
        users: [],
        startDate: '',
        endDate: '',
        description: '',
      });
      setAttachments([]);
      setFiles([]);
      onHide({ reOpen });
    }
    if (response.error) {
      window.toastr.error(response.error.message);
    }
  };

  const handleModalClose = () => {
    reset({
      title: '',
      project: '',
      users: [],
      startDate: '',
      endDate: '',
      description: '',
    });
    setAttachments([]);
    setFiles([]);
    onHide({ reopen: false });
  };
  const renderUsers = () => {
    if (usersData && usersData.length > 0) {
      return usersData?.filter((user) => (user?.UserStatus?.id === userStatusConstants.ACTIVE))
        .map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        }));
    }
    return projectData?.data?.projectStats?.users
      ?.filter((user) => (user?.UserStatus?.id === userStatusConstants.ACTIVE))
      .map((user) => ({
        label: user?.fullName,
        value: user?.id,
      }));
  };

  const renderProjects = () => {
    if (projectName && masterData && masterData.data && masterData.data.projects) {
      // eslint-disable-next-line max-len
      const project = masterData.data.projects.find((projectcheck) => projectcheck.id === projectId);

      if (project) {
        return [{ label: project.name, value: project.id }];
      }
      return masterData.data.projects.map((userProject) => ({
        label: userProject.name,
        value: userProject.id,
      }));

    } if (masterData && masterData.data && masterData.data.projects) {

      return masterData.data.projects.map((userProject) => ({
        label: userProject.name,
        value: userProject.id,
      }));
    }
    return []; // or default options when masterData is not available

  };

  const removeFile = (id) => {
    setFiles((filess) => filess.filter((file) => file.id !== id));
    setAttachments((prevAttachments) => prevAttachments?.filter((attachment) => attachment !== id));
  };

  return (
    <Modal className="mw-500" show={show} onHide={handleModalClose} centered>
      <Modal.Header className="pb-2" closeButton>
        <h2>Add Task</h2>
      </Modal.Header>
      <Modal.Body className="mb-4">
        <Form onSubmit={handleSubmit((data) => onSubmit(data, { reOpen: true }))}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Title<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  style={{ fontWeight: '500', color: 'var(--bs-gray-700)' }}
                  {...register('title', {
                    required: { value: true, message: 'Required' },
                    validate: (value) => {
                      if (value.trim() === '') {
                        return 'Required';
                      }
                      if (value.trim().length < 3) {
                        return "Title can't be less than 3 characters";
                      }
                      if (value.trim().length > 100) {
                        return "Title can't be more then 100 characters";
                      }
                      return true;
                    },
                  })}
                  type="text"
                  placeholder="Task title"
                />
                {errors?.title
                  && <div className="text-danger small"> {errors.title?.message} </div>}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Project<span className="text-danger">*</span></Form.Label>
                <Controller
                  name="project"
                  rules={{
                    required: { value: true, message: 'Required' },
                  }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={renderProjects()}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={customSelectStyles}
                      placeholder="Project"
                      isDisabled={!!projectName}
                    />
                  )}
                />
                {errors?.project
                  && <div className="text-danger small"> {errors.project?.message} </div>}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Users</Form.Label>
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  isClearable
                  options={renderUsers()}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isDisabled={!projectWatched?.value}
                  styles={customSelectStyles}
                  placeholder="Users"
                />
              )}
            />
            {errors?.users
              && <div className="text-danger small"> {errors.users?.message} </div>}
          </Form.Group>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Start date<span className="text-danger">*</span></Form.Label>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FlatPickr
                      options={{
                        dateFormat: 'd-m-Y',
                        minDate: moment(projectData?.data?.projectStats?.startDate).format('DD-MM-YYYY'),
                      }}
                      onChange={(_, currentdateString) => {
                        onChange(currentdateString);
                      }}
                      disabled={!watch('project')?.value}
                      className="form-control"
                      placeholder="Pick a date"
                    />
                  )}
                  rules={{ required: 'Required' }}
                />
                {errors?.startDate
                  && <div className="text-danger small"> {errors.startDate?.message} </div>}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>End date<span className="text-danger">*</span></Form.Label>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FlatPickr
                      options={{
                        dateFormat: 'd-m-Y',
                        minDate: watch('startDate'),
                      }}
                      disabled={!watch('startDate')}
                      onChange={(_, currentdateString) => {
                        onChange(currentdateString);
                      }}
                      placeholder="Pick a date"
                      className="form-control"
                    />
                  )}
                  rules={{ required: 'Required' }}
                />

                {errors?.endDate
                  && <div className="text-danger small"> {errors.endDate?.message} </div>}

              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              style={{ fontWeight: '500', color: 'var(--bs-gray-700)' }}
              {...register('description', {
                maxLength: { value: 500, message: "Description can't be more than 500 characters" },
              })}
              as="textarea"
              rows={3}
            />
            {errors?.description
              && <div className="text-danger small"> {errors.description?.message} </div>}
          </Form.Group>
          <div className="mb-3">
            <div className="col-lg-12">
              <div className="dropzone dropzone-queue mb-2" id="kt_dropzonejs_example_2">
                <input
                  type="file"
                  {...getInputProps({
                    multiple: false,
                  })}
                />
                <Link>
                  <i className="fa fa-paperclip" /> <span {...getRootProps()}> Attach files </span>
                </Link>
                {files?.map((file) => (
                  <div key={file?.id} className="dropzone-items wm-200px">
                    <div className="dropzone-item">
                      <div className="dropzone-file">
                        <div className="dropzone-filename" title="some_image_file_name.jpg">
                          <span data-dz-name>{file?.name}</span>
                        </div>
                      </div>
                      <div className="dropzone-toolbar">
                        <span className="dropzone-start" />
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeFile(file?.id);
                          }}
                          aria-label="remove"
                          className="dropzone-delete border-0 bg-transparent"
                        >
                          <i className="bi bi-x fs-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <Form.Text className="text-muted">
                Max file size is 5MB and max number of files is 5.
              </Form.Text>
            </div>
          </div>
          <div className="justify-content-start text-left pt-5 mt-5">
            <button className="btn btn-sm btn-secondary save-btn" type="submit">
              {!isLoading ? <span className="indicator-label">Save & Next</span>
                : (
                  <span>
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                )}
            </button>
            <button onClick={handleSubmit(onSubmit)} className="btn btn-sm btn-light ms-3 save-btn" type="button">
              {!isLoading ? <span className="indicator-label">Save</span>
                : (
                  <span>
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                )}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddTask;
