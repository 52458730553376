import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { useGetMasterDataQuery } from '../../../Redux/services/masterData';
import Pagination from '../../custom/Pagination';
import { useGetTasksListWithFiltersQuery, useDeleteTaskMutation } from '../../../Redux/services/task';
import AddTaskModal from '../AddTaskModal';
import { useGlobalSearchQuery } from '../../../Redux/services/globalSearch';
import { useGetLoggedUserQuery } from '../../../Redux/services/user';
import useDebounce from '../../custom/useDebounce';
import { userRoleConstants, userStatusConstants } from '../../../utils/constant';
import { decryptRole } from '../../../utils/cryptoUtils';
import { useGetAllMilestonesQuery } from '../../../Redux/services/milestone';

// const statusColorMap = {
//   'To do': 'badge-light-info',
//   'In Progress': 'badge-light-warning',
//   Complete: 'badge-light-success',
// };

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
    fontSize: '13px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: state.isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
};

function TasksList() {
  const navigate = useNavigate();
  const { data: masterData } = useGetMasterDataQuery();
  const [date, setDate] = useState('');
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [filters, setFilters] = useState({});
  const [sortOrder, setOrderSort] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [totalCount, setTaskCount] = useState(0);
  const [showDate, setShowDate] = useState('');
  const [taskSearch, setTaskSearch] = useState('');
  const size = 5;
  const { data: tasks, error } = useGetTasksListWithFiltersQuery({
    filters, sortColumn, sortOrder, page: currentPage, size,
  });
  const [deleteTask] = useDeleteTaskMutation();
  const { data: loggedInUser } = useGetLoggedUserQuery();
  const { data: milestones } = useGetAllMilestonesQuery();
  const {
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: {
      taskId: '',
      title: '',
      project: [],
      date: '',
      users: [],
      // status: [],
      milestone: '',
    },
  });
  const taskDebounced = useDebounce({ value: taskSearch, delay: 500 });
  const { data: globalSearchData } = useGlobalSearchQuery({
    data: { keyword: taskDebounced },
  });

  const roleMemo = useMemo(() => {
    const role = localStorage.getItem('role');
    const roleDecrypted = role ? decryptRole(role) : '';
    return roleDecrypted;
  }, []);

  const closeAddTaskModal = ({ reOpen }) => {
    setShowAddTaskModal(false);
    if (reOpen) {
      setTimeout(() => {
        setShowAddTaskModal(true);
      }, 500);
    }
  };
  const milestoneList = milestones?.data?.map((milestone) => ({
    label: milestone?.name,
    value: milestone?.name,
  }));

  const onSubmit = (filterData) => {
    const {
      title, taskId, project, milestone, users,
    } = filterData;
    const reqBody = {};
    if (title?.value) {
      reqBody.title = title?.value;
    }
    if (taskId?.value) {
      reqBody.taskId = taskId?.value;
    }
    if (project?.length > 0) {
      const projects = project.map((userProject) => userProject.value);
      reqBody.project = projects;
    }
    if (date) {
      const dates = date.split(' - ');
      const startDate = dates[0].trim();
      const endDate = dates[1].trim();
      reqBody.startDate = startDate;
      reqBody.endDate = endDate;
    }
    if (milestone.length > 0) {
      const milestonesData = milestone?.map((m) => m.value);
      reqBody.milestone = milestonesData;
    }
    if (users.length > 0) {
      const usersId = users?.map((user) => user.value);
      reqBody.users = usersId;
    }
    setFilters(reqBody);
    setCurrentPage(1);
  };

  const handleColumnSort = (columnName) => {
    let orderBy = 'asc';
    if (sortColumn === columnName) {
      orderBy = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    setOrderSort(orderBy);
    setSortColumn(columnName);
    setCurrentPage(1);
  };

  const handleClear = () => {
    reset();
    setFilters({});
    setCurrentPage(1);
    setSortColumn('name');
    setOrderSort('asc');
    setDate('');
    setShowDate('');
  };

  const renderTaskIds = () => globalSearchData?.data?.tasks?.map((task) => ({
    label: task?.uniqueTaskId,
    value: task?.uniqueTaskId,
  }));
  const renderTitles = () => globalSearchData?.data?.tasks?.map((task) => ({
    label: task?.title,
    value: task?.title,
  }));
  const renderProjects = () => masterData?.data?.projects?.map((project) => ({
    label: project?.name,
    value: project?.id,
  }));
  // const renderStatus = () => masterData?.data?.taskStatuses?.map((taskStatus) => ({
  //   label: taskStatus?.name,
  //   value: taskStatus?.id,
  // }));
  const renderUsers = () => {
    const users = masterData?.data?.users
      ?.filter((user) => (user.StatusId === userStatusConstants.ACTIVE))
      ?.map((user) => ({
        label: user?.fullName,
        value: user?.id,
      })) ?? [];

    if (roleMemo?.id !== userRoleConstants.USER) {
      return [{ label: 'unassigned', value: 'unassigned' }, ...users];
    }

    return users;
  };

  const handleDate = (event, picker) => {
    const formattedDate = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
    picker.element.val(formattedDate);
    setShowDate(formattedDate);
    const dates = formattedDate.split(' - ');
    const formattedDates = dates.map((data) => moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    const formattedDateString = formattedDates.join(' - ');
    setDate(formattedDateString);
  };

  const handleCancelDate = (event, picker) => {
    picker.element.val('');
    setDate('');
    setShowDate('');
  };

  const handleDelete = (id, e) => {
    e.stopPropagation();
    Swal.fire({
      text: 'Do you want to delete this task?',
      icon: 'warning',
      iconColor: 'var(--bs-primary)',
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: 'Yes',
      cancelButtonColor: '#161221',
      customClass: {
        confirmButton: 'btn btn-sm btn-secondary bg-dark',
        cancelButton: 'btn btn-sm btn-outline-secondary ms-3',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteTask(id);
        if (response?.data?.success) {
          window.toastr.success(response.data.message);
        }
      }
    });
  };

  useEffect(() => {
    if (tasks?.data?.taskCount) {
      let totalTasks = 0;
      tasks.data.milestoneCount.forEach((milestone) => {
        totalTasks += parseInt(milestone.taskCount, 10);
      });
      setTaskCount(totalTasks);
    }
    // eslint-disable-next-line
  }, [tasks?.data?.taskCount]);

  if (error) {
    window.toastr.error('Something went wrong!');
  }

  const theme = document.querySelector('[data-bs-theme]')?.getAttribute('data-bs-theme');

  return (
    <div className="row pt-0 mt-5">
      <div className="col-md-12">
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="pb-0 mb-0 ms-0 pt-0">Tasks</h2>
            <ul className="breadcrumb fw-semibold fs-base mb-1">
              <li className="breadcrumb-item text-muted">
                <Link to="/" className="text-muted text-hover-primary">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item text-gray-900">
                Tasks
              </li>
            </ul>
          </div>
          <div>
            <button
              onClick={() => setShowAddTaskModal(true)}
              type="button"
              className="btn btn-sm btn-primary"
            ><i
              className="fa fa-plus fa-plus me-1"
            />Add
            </button>
          </div>
          {showAddTaskModal && (
          <AddTaskModal
            onHide={closeAddTaskModal}
            show={showAddTaskModal}
          />
          )}
        </div>
        <div className="card priority-card mt-4">
          <div className="card-header d-block pt-3">
            <h5 className="card-title mb-3">Search Filter</h5>
            <form className="row pb-2 g-3 " onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-1">
                <Controller
                  name="taskId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={renderTaskIds()}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable
                      onInputChange={(input) => setTaskSearch(input)}
                      styles={customSelectStyles}
                      placeholder="Task ID"
                    />
                  )}
                />
              </div>
              <div className="col-md-2">
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={renderTitles()}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable
                      onInputChange={(input) => setTaskSearch(input)}
                      styles={customSelectStyles}
                      placeholder="Title"
                    />
                  )}
                />
              </div>
              <div className="col-md-2">
                <Controller
                  name="project"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={renderProjects()}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isMulti
                      styles={customSelectStyles}
                      placeholder="Project"
                    />
                  )}
                />
              </div>

              <div className="col-md-2">
                <DateRangePicker
                  onApply={handleDate}
                  onCancel={handleCancelDate}
                  initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                      cancelLabel: 'Clear',
                      format: 'DD/MM/YYYY',
                    },
                    showDropdowns: true,
                  }}
                >
                  <input
                    type="text"
                    placeholder="Start Date  -  End Date"
                    autoComplete="off"
                    className="form-control"
                    readOnly
                    value={showDate ?? ''}
                  />
                </DateRangePicker>
              </div>

              <div className="col-md-2">
                <Controller
                  name="users"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={renderUsers()}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      isMulti
                      placeholder="Users"
                      styles={customSelectStyles}
                    />
                  )}
                />
              </div>
              {/* <div className="col-md-2">
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={renderStatus()}
                      components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                      }}
                      isMulti
                      placeholder="Status"
                      styles={customSelectStyles}
                    />
                  )}
                />
              </div> */}
              <div className="col-md-2">
                <Controller
                  name="milestone"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={milestoneList}
                      components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                      }}
                      isMulti
                      placeholder="Milestone"
                      styles={customSelectStyles}
                    />
                  )}
                />
              </div>
              <div className="col-md-2">
                <button
                  type="submit"
                  className="btn ntn-sm btn-secondary me-sm-3 me-1"
                >Filter
                </button>
                <button
                  onClick={handleClear}
                  type="button"
                  className="btn btn-label-secondary"
                >Clear
                </button>
              </div>
            </form>
          </div>
          <div className="card-body p-4 py-2 table-responsive">

            <table
              className="table data-table align-middle table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_sales_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('taskId')}>
                    <span role="button">Task ID</span>
                    {sortColumn === 'taskId' && (
                      <span className="sorting-icons m-2">
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                      </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '300px' }} onClick={() => handleColumnSort('title')}>
                    <span role="button">Title</span>
                    {sortColumn === 'title' && (
                      <span className="sorting-icons m-2">
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                      </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '150px' }} onClick={() => handleColumnSort('project')}>
                    <span role="button">Project</span>
                    {sortColumn === 'project' && (
                      <span className="sorting-icons m-2">
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                      </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('startDate')}>
                    <span role="button">Start Date</span>
                    {sortColumn === 'startDate' && (
                      <span className="sorting-icons m-2">
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                      </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '100px' }} onClick={() => handleColumnSort('endDate')}>
                    <span role="button">End Date</span>
                    {sortColumn === 'endDate' && (
                      <span className="sorting-icons m-2">
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                        <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                      </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '120px' }}>Users</th>
                  {/* <th className="sortable" style={{ width: '120px' }}
                  onClick={() => handleColumnSort('status')}>
                    <span role="button">Status</span>
                    {sortColumn === 'status' && (
                      <span className="sorting-icons m-2">
                        <i style={{ transform: 'scale(.7)' }}
                         className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                        <i style={{ transform: 'scale(.7)' }}
                        className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                      </span>
                    )} */}
                  <th className="sortable" style={{ width: '120px' }} onClick={() => handleColumnSort('milestone')}>
                    <span>MILESTONE</span>
                    {sortColumn === 'milestone' && (
                    <span className="sorting-icons m-2">
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-up ${sortOrder === 'desc' ? 'd-none' : ''}`} />
                      <i style={{ transform: 'scale(.7)' }} className={`fa fa-chevron-down ${sortOrder === 'asc' ? 'd-none' : ''}`} />
                    </span>
                    )}
                  </th>
                  <th className="sortable" style={{ width: '100px' }}>Actions</th>

                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {tasks?.data?.tasks.map((task) => (
                  <tr key={task?.id} onClick={() => navigate(`/tasks/${task?.uniqueTaskId}`)}>
                    <td style={{ width: '100px' }}><span className="text-gray-800 fs-5 fw-bold">{task?.uniqueTaskId}</span></td>
                    <td style={{ width: '300px' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id="button-tooltip">{task.title}</Tooltip>}
                      >
                        <div style={{ maxWidth: '250px' }} className="text-truncate">
                          <span className="text-gray-800 fs-5 fw-bold">{task?.title}</span>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td style={{ width: '150px' }}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id="button-tooltip">{task?.Project?.name}</Tooltip>}
                      >
                        <div style={{ maxWidth: '130px' }} className="text-truncate">
                          <span className="text-gray-800 fs-5 fw-bold">{task?.Project?.name}</span>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td style={{ width: '100px' }}>{task?.startDate ? moment(task?.startDate, 'YYYY-MM-DD').format('DD MMM YY') : '---'}</td>
                    <td style={{ width: '100px' }}>{task?.endDate ? moment(task?.endDate, 'YYYY-MM-DD').format('DD MMM YY') : '---'}</td>
                    <td style={{ width: '120px' }}>
                      <div className="symbol-group symbol-hover mb-1">
                        {task?.taskUsers?.length > 3 ? (
                          <>
                            {task?.taskUsers?.slice(0, 2).map((user, index) => {
                              const backgroundColors = ['bg-warning', 'bg-primary'];
                              const textColors = ['text-inverse-warning', 'text-inverse-primary'];
                              const bgColor = backgroundColors[index % 2];
                              const textColor = textColors[index % 2];
                              return (
                                <div
                                  key={user.id}
                                  className="symbol symbol-25px symbol-circle"
                                  data-bs-toggle="tooltip"
                                  title={user.fullName}
                                >
                                  <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                    {user.initials}
                                  </span>
                                </div>
                              );
                            })}
                            <span
                              className="symbol symbol-25px symbol-circle"
                              data-bs-toggle="tooltip"
                              title={`+${task.taskUsers.length - 2} more`}
                            >
                              <span className="symbol-label solid fs-7 fw-normal">+{task.taskUsers.length - 2}</span>
                            </span>
                          </>
                        ) : task?.taskUsers?.map((user, index) => {
                          const backgroundColors = ['bg-warning', 'bg-danger'];
                          const textColors = ['text-inverse-warning', 'text-inverse-danger'];
                          const bgColor = backgroundColors[index % 2];
                          const textColor = textColors[index % 2];
                          return (
                            <div
                              key={user.id}
                              className="symbol symbol-25px symbol-circle"
                              data-bs-toggle="tooltip"
                              title={user.fullName}
                            >
                              <span className={`symbol-label ${index % 2 === 0 ? 'solid' : 'outline'} fs-7 fw-normal ${bgColor} ${textColor}`}>
                                {user.initials}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      {task?.taskUsers.length === 0 && <div>  --- </div>}
                    </td>
                    {/* <td style={{ width: '120px' }}>
                      <div className={`badge ${statusColorMap[task?.TaskStatus?.name]}
                       fw-bold`}>{task?.TaskStatus?.name}
                      </div>
                    </td> */}

                    <td style={{ width: '120px' }}>
                      <div
                        className="badge fw-bold"
                        style={{
                          color: theme === 'light' ? task?.Milestone?.MilestoneColor?.textHexCode : task?.Milestone?.MilestoneColor?.darkTextHexCode,
                          backgroundColor: theme === 'light' ? task?.Milestone?.MilestoneColor?.backgroundHexCode : task?.Milestone?.MilestoneColor?.darkBackgroundHexCode,
                        }}
                      >
                        {task?.Milestone?.name}
                      </div>
                    </td>
                    <td style={{ width: '100px' }}>
                      <div>
                        <Link to={`/tasks/${task?.uniqueTaskId}`}><i className="fa fa-eye me-3" aria-label="View Task Details" /></Link>
                        {loggedInUser?.data?.RoleId === userRoleConstants.ADMIN
                          && <i className="fa fa-trash" aria-label="Delete Task" title="Delete Task" onClick={(e) => handleDelete(task?.id, e)} onKeyDown={(e) => e.key === 'Enter' && handleDelete(task?.id, e)} role="button" tabIndex={0} />}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {tasks?.data?.tasks?.length > 0
              ? (
                <Pagination
                  dataPerPage={size}
                  totalData={totalCount}
                  currentPage={currentPage}
                  paginate={setCurrentPage}
                />
              )
              : <div className="text-center fw-bold mx-auto m-4">No Records Found!</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TasksList;
