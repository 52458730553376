import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { useUploadAttachmentsMutation } from '../../../Redux/services/task';

const fileIconsMap = {
  '.pdf': 'fa-file-pdf',
  '.jpeg': 'fa-file-image',
  '.jpg': 'fa-file-image',
  '.png': 'fa-file-image',
  '.csv': 'fa-file-csv',
  '.doc': 'fa-file-lines',
  '.docx': 'fa-file-lines',
  '.xls': 'fa-file-excel',
  '.xlsx': 'fa-file-excel',
};
function TaskAttachments({
  setAttachments, taskData, setFiles, files,
}) {
  const [uploadAttachments, { isLoading }] = useUploadAttachmentsMutation({ data: files });

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if ((Array.isArray(taskData?.Documents))
      && (files.length + taskData.Documents.length) >= 5) {
      return window.toastr.error('Total Number Files exceeds 5');
    }

    if (acceptedFiles?.length) {
      try {
        const attachments = {
          ...acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file),
          })),
        };
        const response = await uploadAttachments({ data: attachments });
        if (response?.data?.success) {
          setFiles((previousFiles) => [
            ...previousFiles,
            ...acceptedFiles.map((file) => Object.assign(file, {
              preview: URL.createObjectURL(file),
              id: response?.data?.data[0],
            })),
          ]);
          setAttachments((prevIds) => ([...prevIds, response?.data?.data[0]]));
        } else {
          window.toastr.error(response?.error?.data?.message ?? 'File could not be uploaded, please try again');
        }

      } catch (error) {
        window.toastr.error(error?.message);
      }

    }
    if (rejectedFiles?.length) {
      if (rejectedFiles[0]?.errors?.[0]?.code === 'file-invalid-type') {
        window.toastr.error('File type is not acceptable');
      } else if (rejectedFiles[0]?.errors?.[0]?.code === 'too-many-files') {
        window.toastr.error('Upload only one file at a time');
      } else if (rejectedFiles[0]?.errors?.[0]?.code === 'file-too-large') {
        window.toastr.error('Exceeds max file size');
      } else {
        window.toastr.error('Exceeds max file size / file type is not acceptable');
      }
    }
    return null;
    // eslint-disable-next-line
  }, [files.length, taskData?.Documents?.length]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg'],
      'text/plain': ['.txt'],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/msword': ['.doc'],
      'application/pdf': [],
      'text/csv': ['.csv'],
    },
    noDragEventsBubbling: true,
    maxSize: 1024 * 1024 * 5,
    onDrop,
    maxFiles: 1,
  });

  useEffect(
    () => () => files?.forEach((file) => URL.revokeObjectURL(file.preview)),
    [files],
  );

  const removeFile = (id) => {
    setFiles((filess) => filess.filter((file) => file.id !== id));
    setAttachments((prevAttachments) => prevAttachments?.filter((attachment) => attachment !== id));
  };

  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header mt-6 minimize">
        <div className="card-title flex-column">
          <h2 className="mb-1">Documents</h2>
          <div className="fs-6 fw-semibold text-muted">All relevant
            documents
          </div>
        </div>
        <div className="card-toolbar mt-0">
          <button
            type="button"
            className="btn btn-icon btn-sm btn-light-primary justify-content-center"
            aria-label="collapsible"
          >
            <i className="fas fa-plus" />
          </button>
        </div>
      </div>
      <div className="card-body flex-column collapse">
        <div className="row">
          <div className="col-md-4 mt-5 mb-4">
            <form className="form">
              <div className="fv-row">
                <div className={`dropzone dz-clickable position-relative ${taskData?.Documents?.length >= 5 && 'shake'} disabled`}>
                  {isLoading
                    && (
                    <div
                      style={{
                        zIndex: '10', left: '50%', top: '50%', transform: 'translate(-50%, -50%) scale(2)',
                      }}
                      className="position-absolute"
                    >
                      <span>
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </div>
                    )}
                  <div
                    {...getRootProps({
                      className: `dz-message needsclick ${taskData?.Documents?.length >= 5 && 'disabled'}`,
                    })}
                    style={{
                      display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', pointerEvents: taskData?.Documents?.length >= 5 ? 'none' : 'auto',
                    }}
                  >
                    <input {...getInputProps({
                      disabled: taskData?.Documents?.length >= 5,
                      multiple: false,
                    })}
                    />
                    {!files?.length > 0
                      ? (
                        <div
                          className="d-flex"
                        >
                          <i
                            className="bi bi-file-earmark-arrow-up text-primary fs-3x"
                          />
                          <div className="ms-4">
                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                              Drop files here or click to upload.
                            </h3>
                            <span
                              className="fs-7 fw-semibold text-gray-500"
                            >Upload up to 5 files
                            </span>
                          </div>
                        </div>
                      )
                      : (
                        files.map((file) => (
                          <div
                            className={`dz-preview ${file?.type.split('/')[0] !== 'image' && 'dz-file-preview'} dz-processing dz-error dz-complete dz-image-preview`}
                            key={file.id}
                            style={{ width: '120px', height: '120px', backgroundColor: 'var(--bs-gray-200)' }}

                          >
                            <button
                              type="button"
                              className="dz-remove"
                              aria-label="Close"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFile(file.id);
                              }}
                            />
                            {file?.type.split('/')[0] === 'image' && (
                            <div
                              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              className="dz-image"
                            >
                              <img
                                src={file.preview}
                                width={100}
                                height={100}
                                onLoad={() => {
                                  URL.revokeObjectURL(file.preview);
                                }}
                                alt={file?.name}
                              />
                            </div>
                            )}
                            <div
                              className="dz-details"
                            >
                              <div className="dz-filename">
                                <span data-dz-name>{file.name}</span>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                  </div>
                </div>
                <span className="fs-7 fw-semibold text-gray-500"><sup className="text-danger">*</sup> Max upload size 5 mb. </span><br />
                {taskData?.Documents?.length >= 5 && <span className="fs-7 fw-semibold text-gray-500"><sup className="text-danger">*</sup> Document upload limit reached for this task. </span>}
              </div>
            </form>
          </div>
          <div className="col-md-8 table-responsive">
            <table
              className="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_sales_table"
            >
              <thead>
                <tr
                  className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th className="min-w-175px">File</th>
                  <th className="min-w-100px">Type</th>
                  <th className="min-w-100px">Uploaded On</th>
                  <th className="min-w-70px">Uploaded By</th>
                  <th className="min-w-100px">Actions</th>

                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {taskData?.Documents?.map((document) => (
                  <tr key={document?.id} className="header-level">

                    <td>{document?.fileName}</td>
                    <td aria-label="type"><i
                      className={`fa fa-regular ${fileIconsMap[document?.type] ?? ''} fs-3 text-danger`}
                    />
                    </td>
                    <td>{document?.uploadedAt}</td>
                    <td>{document?.uploadedBy}</td>
                    <td aria-label="download">
                      <Link to={document?.link} title="download"><i
                        className="fa-solid fa-download "
                      />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  );
}

export default TaskAttachments;
